import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import "./Overlay.css";
import Header from "./Header";

export type OverlayProps = {
  style?: CSSProperties;
  children?: ReactNode;
  visible: boolean;
  onClick?: MouseEventHandler;
};

export default function Overlay({ style, children, visible, onClick }: OverlayProps) {
  return (
    <div className="overlay" onClick={onClick} style={{
      ...style,
      opacity: (visible)?(1):(0),
      pointerEvents: (visible)?("auto"):("none")
      }}>
      <Header style={{
        pointerEvents: "none",
        opacity: 0,
        zIndex: 0
      }}/>

      <div className="overlay-content">
        {children}
      </div>
    </div>
  );
}