import gulstenstudioLogo from "../assets/media/processed/gulstenstudio-logo-white.png";
import ateljeLogo from "../assets/media/processed/atelje-logo-white.png";
import "./Header.css";
import { CSSProperties, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export type HoveredItemTypes = "gulstenstudio" | "atelje";

export type HeaderProps = {
  activePath?: HoveredItemTypes;
  hoveredPath?: HoveredItemTypes | null;
  onHover?: (path: HoveredItemTypes | null) => void;
  style?: CSSProperties;
};

export default function Header({
  activePath,
  hoveredPath,
  onHover,
  style,
}: HeaderProps) {
  const navigate = useNavigate();

  const handleHover = useCallback(
    (path: HoveredItemTypes | null) => {
      if (onHover) {
        onHover(path);
      }
    },
    [onHover]
  );

  return (
    <header className="header" data-hovered-path={hoveredPath} style={style}>
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive && hoveredPath !== "atelje"
            ? "header-item header-item-active"
            : "header-item"
        }
        onClick={() => {
          navigate("/");

          if(isMobile) {
            if(hoveredPath === "gulstenstudio") {
              handleHover(null);
            }
            else {
              handleHover("gulstenstudio");
            }
          }
        }}
        onMouseEnter={() => !isMobile && handleHover("gulstenstudio")}
        onMouseLeave={() => !isMobile && handleHover(null)}
      >
        <div title="gul.sten.studio">
          <img src={gulstenstudioLogo} alt="gul.sten.studio" />
        </div>
      </NavLink>

      <NavLink
        to="/atelje"
        className={({ isActive }) =>
          hoveredPath === "atelje"
            ? "header-item header-item-inactive"
            : isActive
            ? "header-item header-item-active"
            : "header-item"
        }
      >
        <div
          title="ateljé Sofia Larson"
          onClick={() => {
            navigate("/atelje");
  
            if(isMobile) {
              if(hoveredPath === "atelje") {
                handleHover(null);
              }
              else {
                handleHover("atelje");
              }
            }
          }}
          onMouseEnter={() => !isMobile && handleHover("atelje")}
          onMouseLeave={() => !isMobile && handleHover(null)}
        >
          <img src={ateljeLogo} alt="ateljé Sofia Larson" />
        </div>
      </NavLink>
    </header>
  );
}
