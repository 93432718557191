import { Fragment, useEffect, useRef, useState } from "react";
import "./../Image.css";

export default function HomePage() {
  const firstImageRef = useRef<HTMLDivElement>(null);
  const firstImageImgRef = useRef<HTMLImageElement>(null);
  const [showAllImages, setShowAllImages] = useState(false);

  function update() {
    if(!firstImageRef.current || !firstImageImgRef.current) {
      return;
    }

    const root = document.querySelector<HTMLDivElement>(":root");

    if(root && firstImageRef.current) {
      root.style.setProperty("--first-image-offset", `calc(${(firstImageRef.current.offsetTop + firstImageRef.current.offsetHeight)}px + 4rem)`);
    }
  };

  useEffect(() => {
    const eventListener = () => {
      if(firstImageRef.current) {
        update();
      }
    };

    window.addEventListener("scroll", eventListener);

    return () => {
      window.removeEventListener("scroll", eventListener);
    };
  }, [ firstImageRef ]);

  useEffect(() => {
    const current = firstImageImgRef.current;

    if(current) {
      update();

      const loadEventListener = () => {
        setShowAllImages(true);

        const root = document.querySelector<HTMLDivElement>(":root");

        if(root) {
          update();

          window.requestAnimationFrame(() => {
            root.style.setProperty("--first-image-loaded", "1s");
          });
        }
      }

      current.addEventListener("load", loadEventListener);

      return () => {
        if(current) {
          current.removeEventListener("load", loadEventListener);
        }
      };
    }
  }, [ firstImageImgRef ]);

  return (
    <Fragment>
      <div className="image-container">
        <div ref={firstImageRef} className="image">
          <img ref={firstImageImgRef} alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/bdb6fd05-3524-4a61-0106-cd6b2d144400/original"}/>
        </div>
        
        <div className="image image-right">
          <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/2e46c193-edac-40e6-45b8-d89c06897300/original"}/>
        </div>

        <div className="image image-right">
          <img alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/ad3f0f90-ce52-4e92-b0d6-a67e1b35cf00/original"}/>
        </div>

        <div className="image image-right">
          <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/6a1a778a-df58-480e-00ab-07dcc2ff3e00/original"}/>
        </div>

        {(showAllImages) && (
          <Fragment>
            <div className="image">
              <img alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/41881b26-f785-4424-eb62-1f78dc1dd000/original"}/>
            </div>

            <div className="image image-right">
              <img alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/0b0e7e92-b105-48a2-bf8f-9a9a65af8100/original"}/>
            </div>

            <div className="image image-end">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/e9f68976-8c3a-425f-58ae-502e4c4c4200/original"}/>
            </div>

            <div className="image">
              <img alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/bdac7eb5-9f76-4e08-e244-6fe3c12ec600/original"}/>
            </div>

            <div className="image image-end">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/dfde305a-ebf6-4919-a88f-8fa083d23a00/original"}/>
            </div>

            <div className="image image-right">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/176cb9ef-655c-49a5-dc27-aac2895b0f00/original"}/>
            </div>

            <div className="image">
              <img alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/d366333a-c70d-462f-a92f-6b79cb32f500/original"}/>
            </div>

            <div className="image image-end">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/e3eef428-bccd-4dc9-6dbc-c441c3af9300/original"}/>
            </div>

            <div className="image image-right">
              <img alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/4e1913b0-f7fe-4439-785f-1c5b8e4b8d00/original"}/>
            </div>

            <div className="image image-right">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/0d7c3d9b-9851-4b3b-18d8-49251c035600/original"}/>
            </div>

            <div className="image image-end">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/55c2bcfe-d11b-461c-c800-673299800e00/original"}/>
            </div>

            <div className="image">
              <img alt="" className="image-horizontal" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/c2655224-ebc3-43fe-189d-96a2b42d0a00/original"}/>
            </div>

            <div className="image image-right">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/732cad1f-1a61-4736-09f2-d81136e9c200/original"}/>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
