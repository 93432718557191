import arrowUpBlack from "../assets/media/arrow-up-black.png";
import "./Footer.css";
import { useCallback, useEffect, useState } from "react";
import { HoveredItemTypes } from "./Header";

export type FooterProps = {
  hoveredPath?: HoveredItemTypes | null;
}

export default function Footer({ hoveredPath }: FooterProps) {
  const [ arrowImage, setArrowImage ] = useState<"down" | "up">("down");

  useEffect(() => {
    const eventListener = () => {
      if(window.scrollY > 10) {
        if(arrowImage === "down") {
          setArrowImage("up");
        }
      }
      else if(arrowImage === "up") {
        setArrowImage("down");
      }
    };
    
    window.addEventListener("scroll", eventListener);

    return () => {
      window.removeEventListener("scroll", eventListener);
    };
  }, [ arrowImage ]);

  const handleOnClick = useCallback(() => {
    if(arrowImage === "up") {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }, [ arrowImage ]);

  return (
    <footer className={(hoveredPath)?("footer footer-hovered"):((arrowImage === "up")?("footer footer-scrolled"):("footer"))}>
      <div className="footer-content">
        <div className="footer-anchor" style={{
          opacity: (hoveredPath)?(0):(1)
        }}>
          <img alt={""} src={arrowUpBlack} style={{
            transform: (arrowImage === "down")?("rotateZ(180deg)"):("none"),
            cursor: (arrowImage === "up")?("pointer"):("unset")
          }} onClick={handleOnClick}/>
        </div>

        <div className="footer-links" style={{
          color: (hoveredPath === "atelje")?("#E6E7E8"):("inherit")
        }}>
          <p>Sofia Larson, arkitekt SAR/MSA SIR/MSA</p>
          <p><a href="mailto:sofia@gulstenstudio.se">sofia@gulstenstudio.se</a></p>
          <p><a href="tel:+460725802893">+46 (0) 725 80 28 93</a></p>
        </div>
      </div>
    </footer>
  );
}
