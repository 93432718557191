import { Fragment, useEffect, useRef, useState } from "react";

export default function AteljePage() {
  const firstImageImgRef = useRef<HTMLImageElement>(null);
  const [showAllImages, setShowAllImages] = useState(false);


  useEffect(() => {
    const current = firstImageImgRef.current;

    if(current) {
      const loadEventListener = () => {
        setShowAllImages(true);

        const root = document.querySelector<HTMLDivElement>(":root");

        if(root) {
          window.requestAnimationFrame(() => {
            root.style.setProperty("--first-image-loaded", "1s");
          });
        }
      }

      current.addEventListener("load", loadEventListener);

      return () => {
        if(current) {
          current.removeEventListener("load", loadEventListener);
        }
      };
    }
  }, [ firstImageImgRef ]);

  return (
    <Fragment>
      <div className="image-container">
        <div className="image image-right">
          <img ref={firstImageImgRef} alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/677ec718-ad7c-42a9-9e89-61bf51766700/original"}/>
        </div>

        <div className="image image-right">
          <div className="image-vertical image-hoverable">
            <img alt="" src={require("../../assets/portfolio/atelje/Atelje_002.jpg")}/>

            <div className="image-vertical image-overlay">
              #1<br/><br/>100 x 135<br/><br/>
            </div>
          </div>
        </div>

        <div className="image image-end">
          <div className="image-vertical image-hoverable">
            <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/3c4bc140-a26f-4344-68b8-7d1330f97e00/original"}/>
        
            <div className="image-vertical image-overlay">
              #2<br/><br/>200 x 130<br/><br/>Såld
            </div>
          </div>
        </div>

        <div className="image image-right">
          <div className="image-horizontal image-hoverable">
            <img alt="" src={require("../../assets/portfolio/atelje/Atelje_004.jpg")}/>
            
            <div className="image-horizontal image-overlay">
              #3<br/><br/>200 x 130<br/><br/>
            </div>
          </div>
        </div>
        
        {(showAllImages) && (
          <Fragment>
            <div className="image image-right">
              <img className="image-vertical" alt="" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/160fcda6-7731-49f0-c8dc-37d9e9dcef00/original"}/>
            </div>
            
            <div className="image">
              <div className="image-horizontal image-hoverable">
                <img alt="" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/c15b8174-9c81-4ce0-3d1b-3546cb02c200/original"}/>

                <div className="image-horizontal image-overlay">
                  #4<br/><br/>200 x 135<br/><br/>
                </div>
              </div>
            </div>

            <div className="image image-end">
              <div className="image-vertical image-hoverable">
                <img alt="" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/3f3b4a6f-8255-4e57-8101-d516b5035a00/original"}/>

                <div className="image-vertical image-overlay">
                  #5<br/><br/>300 x 195<br/><br/>
                </div>
              </div>
            </div>
            
            <div className="image image-right">
              <div className="image-vertical image-hoverable">
                <img alt="" src={require("../../assets/portfolio/atelje/Atelje_008.jpg")}/>

                <div className="image-vertical image-overlay">
                  #6<br/><br/>60 x 87<br/><br/>Såld
                </div>
              </div>
            </div>

            <div className="image image-right">
              <img alt="" className="image-vertical" src={"https://imagedelivery.net/iF-n-0zUOubWqw15Yx-oAg/350eb1a0-3466-4f61-6b91-faf3402b3200/original"}/>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
