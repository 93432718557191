import { MouseEventHandler } from "react";
import Overlay from "../Overlay";

export type GulStenStudioOverlayProps = {
  visible: boolean;
  onClick?: MouseEventHandler;
}

export default function GulStenStudioOverlay({ visible, onClick }: GulStenStudioOverlayProps) {
  return (
    <Overlay visible={visible} onClick={onClick} style={{
      backgroundColor: "rgba(219, 162, 56, 0.50)",
    }}>
      <div className="overlay-text">
        Gul sten studio är ett arkitektkontor där konstnärlig verkshöjd förenas med funktionalitet. Med fokus på unika detaljer och gedigna material designar gul sten studio miljöer som är vackra, och åldras vackert. Studion drivs av grundaren Sofia som med åtta års erfarenhet från Wingårdhs arkitekter och med utbildning från Konstfack och Chalmers vet hur man skapar helgjutna miljöer. Hon har även stor erfarenhet av myndighetsfrågor samt av byggprocessen. Gul sten studio specialiserar sig på privatbostäder och interiörer, och är framförallt intresserade av det unika. Söker du också efter det lilla extra i det vardagliga eller vill skapa något ingen sett tidigare?<br/><br/>Välkommen med förfrågningar till sofia@gulstenstudio.se 
      </div>
    </Overlay>
  );
}