import { MouseEventHandler } from "react";
import Overlay from "../Overlay";

export type AteljeOverlayProps = {
  visible: boolean;
  onClick?: MouseEventHandler;
}

export default function AteljeOverlay({ visible, onClick }: AteljeOverlayProps) {
  return (
    <Overlay visible={visible} onClick={onClick} style={{
      backgroundColor: "rgba(40, 8, 0, 0.70)"
    }}>
      <div className="overlay-text overlay-text-right" style={{
        color: "#E6E7E8"
      }}>
        I ateljén arbetar Sofia med stora oljemålningar. Med arkitekturen som inspiration och<br/>en process som genererar många lager ovanpå varandra. Så skapas en struktur.<br/>Ett djup, som avslöjar att något existerar där under. Pris lämnas på förfrågan.
      </div>
    </Overlay>
  );
}