import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import AteljePage from "./components/pages/AteljePage";
import "./index.css";
import Page from "./components/Page";

ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
).render(
  <React.StrictMode>
    <style dangerouslySetInnerHTML={{
      __html: `<style>
        :root {
          --initial-100vh: ${window.innerHeight}px;
        }
      </style>`
    }}>

    </style>

    <BrowserRouter>
      <Page>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/atelje" element={<AteljePage/>}/>
        </Routes>
      </Page>
    </BrowserRouter>
  </React.StrictMode>
);
