import { Fragment, ReactNode, useEffect, useState } from "react";
import GulStenStudioOverlay from "./overlays/GulStenStudioOverlay";
import AteljeOverlay from "./overlays/AteljeOverlay";
import Header, { HoveredItemTypes } from "./Header";
import Footer from "./Footer";

export type PageProps = {
  children: ReactNode;
}

export default function Page({ children }: PageProps) {
  const [ hoveredPath, setHoveredPath ] = useState<HoveredItemTypes | null>(null);

  useEffect(() => {
    const eventListener = () => {
      if(hoveredPath) {
        if(window.scrollY > (window.screen.height * 0.1)) {
          setHoveredPath(null);
        }
      }
    };
    
    window.addEventListener("scroll", eventListener);

    return () => {
      window.removeEventListener("scroll", eventListener);
    };
  }, [ hoveredPath, setHoveredPath ]);

  return (
    <Fragment>
      <GulStenStudioOverlay visible={hoveredPath === "gulstenstudio"} onClick={() => setHoveredPath(null)}/>
      <AteljeOverlay visible={hoveredPath === "atelje"} onClick={() => setHoveredPath(null)}/>

      <Header activePath="gulstenstudio" hoveredPath={hoveredPath} onHover={(path) => setHoveredPath(path)}/>

      <div style={{
        transition: "opacity 1s",
        opacity: (hoveredPath === "atelje")?(0.3):((hoveredPath === "gulstenstudio")?(0.2):(1))
      }}>
        {children}
      </div>

      <Footer hoveredPath={hoveredPath}/>
    </Fragment>
  );
}